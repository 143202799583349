<template>
  <v-layout>
    <base-list-view
      title="Локальные документы"
      label-text-search="Поиск документа"
      @onRefresh="init"
      @onDelete="del"
      :dataSource.sync="dataSource"
      :headers="headers"
      :loading="loading"
      pageEditName="LocalDocumentEdit"
      :multiple-save="[
        { value: 'more', text: 'Создать документ' },
        ...documentTemplatesData,
      ]"
      @add="add"
      :not-show-actions="readonly ? ['add'] : []"
      :showDelete="!readonly"
      :showCopy="!readonly"
      :selectedItems="selected"
      @toggle-select-all="selectAllToggle"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :item-class="
        (item) => {
          return {
            'font-weight-bold': pageType === 'my' && !item.Acquainted,
            disabled: item.Attribute.Disabled,
          };
        }
      "
      :showFilters="true"
      :options.sync="options"
      :server-items-length="totalCount"
      group-by="ActivitySection.Name"
      server-options
      show-groups
    >
      <template v-slot:item.data-table-select="{ item, isSelected, select }">
        <v-simple-checkbox
          :value="isSelected"
          :ripple="false"
          :disabled="item.Attribute.Disabled"
          @input="select($event)"
        ></v-simple-checkbox>
      </template>
      <template #group.header="{ group, items }">
        <span class="subtitle-2"
          >{{ groupKey(group) }} ({{ items.length }})</span
        >
      </template>
    </base-list-view>
  </v-layout>
</template>

<script>
import { mapActions } from "vuex";
import Vue from "vue";
import ListEditMixin from "../../mixins/ListEditMixin";
import PaginationListMixin from "../../mixins/PaginationListMixin";
import LocalDocumentService from "@/services/LocalDocumentService";
import BaseListView from "../../layouts/BaseListView.vue";
import { OrganizationDocumentStatus } from "../../data/enums";
import DocumentTemplateService from "../../services/document/DocumentTemplateService";
import Permissions from "../../data/permissions";

export default {
  name: "view-LocalDocuments",
  components: {
    BaseListView,
  },
  mixins: [ListEditMixin, PaginationListMixin],
  data: function () {
    return {
      selectType: 0,
      sortBy: ["Date", "Num"],
      sortDesc: [true, true],
      DocumentPrint: {
        Content: null,
      },
      readonly: !this.hasPermission(Permissions.OrganizationDocumentsEdit),
      apiService: LocalDocumentService,
      loading: true,
      headers: [
        {
          text: "Раздел",
          value: "ActivitySection.Name",
          width: 220,
        },
        {
          text: "Тип документа",
          value: "Type.Name",
          width: 220,
          dataType: "object",
          displayText: (e) => e,
          dict: { serverName: "OrganizationDocument.Type.Name" }, // Для фильтра. Загрузка словаря с сервера, с пагинацией
        },
        { text: "Номер", value: "Num", width: "1%", notGroup: true },
        {
          text: "Дата",
          value: "Date",
          dataType: "Date",
          width: "1%",
          notGroup: true,
          displayText: Vue.filter("DateShort"),
        },
        { text: "Наименование", value: "Name", notGroup: true },
        {
          text: "Статус",
          value: "Status",
          dataType: "enum",
          options: OrganizationDocumentStatus.slice(1),
          width: 180,
        },
      ],
      dataSource: [],
      selected: [],
      documentTemplates: [],
      groupKeyIds: [],
    };
  },
  computed: {
    _apiServiceParams() {
      return {
        type: this.pageType,
        participance: !this.hasPermission(
          Permissions.OrganizationDocumentViewAll
        ),
      };
    },
    pageType() {
      return this.currentRouter.params?.type ?? "my";
    },
    documentTemplatesData() {
      return this.documentTemplates
        .map((e) => ({ value: e.Id, text: e.Name }))
        .sort((a, b) => a.text?.localeCompare(b.text));
    },
  },
  created() {
    this.LOAD_ORGGANIZATION_DOCUMENT_TYPES();
    this.LOAD_AUDIT_INDICATOR_CATEGORIES();
  },
  async mounted() {},
  methods: {
    ...mapActions("loaderDataSources", ["LOAD_ORGGANIZATION_DOCUMENT_TYPES"]),
    ...mapActions(["LOAD_AUDIT_INDICATOR_CATEGORIES"]),
    selectAllToggle() {
      const selectedCount = this.selected.length;
      const selectableCount = this.dataSource.filter(
        (item) => !item.Attribute.Disabled
      ).length;

      if (selectedCount !== selectableCount) {
        this.selected = this.dataSource.filter(
          (item) => !item.Attribute.Disabled
        );
      } else {
        this.selected = [];
      }
    },
    async documentPrint(item) {
      if (item.Id === this.DocumentPrint?.Id) {
        this.$refs.rich.documentPrint();
        return;
      }

      const { data } = await LocalDocumentService.show(item.Id);
      this.DocumentPrint = data;
    },
    async add(item) {
      await this.refreshTemplates();
      if (item.value === "more") {
        if (this.documentTemplatesData.length === 0) {
          this.$alert("Необходимо создать хотя бы один шаблон документа.");
        }
        return;
      }
      if (
        this.documentTemplatesData.findIndex((d) => d.value === item.value) !==
        -1
      )
        this.$router.push({
          name: "LocalDocumentEdit",
          params: {
            objectId: 0,
          },
          query: {
            templateId: item.value,
            type: "create",
          },
        });
      else {
        Vue.prototype.$alert("Шаблон не найден, возможно он был удален.");
      }
    },
    async refreshTemplates() {
      const params = {
        archive: false,
        isSample: false,
      };
      this.documentTemplates = (await DocumentTemplateService.get(params)).data;
    },
    DataFromServerNormalize(data) {
      return data;
    },
    async loadingDataSource() {
      let res;
      try {
        this.loading = true;
        res = this.DataFromServerNormalize(
          await this.groupInit(this._apiServiceParams)
        );
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
      if (this.virtualPage !== 1) {
        this.dataSource = [...this.dataSource, ...res];
      } else this.dataSource = res;
    },
    async init() {
      this.refreshTemplates();
      this.resetPagination();
      this.loadingDataSource();
    },
  },
};
</script>
<style lang="scss">
.local-document__select-type {
  width: 275px;
  height: 100%;
  margin-left: 10px;
  margin-right: 10px;
  box-shadow: 2px 8px 30px var(--v-overlay-base);
  .v-list-item__content {
    color: var(--v-blue-grey-base);
  }
}
</style>
