var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-layout',[_c('base-list-view',{attrs:{"title":"Локальные документы","label-text-search":"Поиск документа","dataSource":_vm.dataSource,"headers":_vm.headers,"loading":_vm.loading,"pageEditName":"LocalDocumentEdit","multiple-save":[
      { value: 'more', text: 'Создать документ' },
      ..._vm.documentTemplatesData,
    ],"not-show-actions":_vm.readonly ? ['add'] : [],"showDelete":!_vm.readonly,"showCopy":!_vm.readonly,"selectedItems":_vm.selected,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"item-class":(item) => {
        return {
          'font-weight-bold': _vm.pageType === 'my' && !item.Acquainted,
          disabled: item.Attribute.Disabled,
        };
      },"showFilters":true,"options":_vm.options,"server-items-length":_vm.totalCount,"group-by":"ActivitySection.Name","server-options":"","show-groups":""},on:{"onRefresh":_vm.init,"onDelete":_vm.del,"update:dataSource":function($event){_vm.dataSource=$event},"update:data-source":function($event){_vm.dataSource=$event},"add":_vm.add,"toggle-select-all":_vm.selectAllToggle,"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.data-table-select",fn:function({ item, isSelected, select }){return [_c('v-simple-checkbox',{attrs:{"value":isSelected,"ripple":false,"disabled":item.Attribute.Disabled},on:{"input":function($event){return select($event)}}})]}},{key:"group.header",fn:function({ group, items }){return [_c('span',{staticClass:"subtitle-2"},[_vm._v(_vm._s(_vm.groupKey(group))+" ("+_vm._s(items.length)+")")])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }